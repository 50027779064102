import api from "../index";
import urls from "./url";

export default {
  //获取model
  getModel(params, header) {
    return api.post(urls.modelUrl, params, header);
  },
  //获取跟读列表
  getFollowList(params, header) {
    return api.post(urls.listUrl, params, header);
  },
  //1上传录音文件
  uploadFile(params, header) {
    return api.post(urls.uploadFileUrl, params, header);
  },
  //2评测打分
  ora(params, header) {
    return api.post(urls.oraUrl, params, header);
  },
  //3保存用户得分
  saveUserScore(params, header) {
    return api.post(urls.saveUserUrl, params, header);
  },
  //获取openid
  getOpenid(params, header) {
    return api.post(urls.getOpenidUrl, params, header);
  },
  //上一个
  getPre(params, header) {
    return api.post(urls.getPreUrl, params, header);
  },
  //下一个
  getNext(params, header) {
    return api.post(urls.getNextUrl, params, header);
  },
  //2023.11.14 +
  //首页-目录列表
  getIndexList(params, header) {
    return api.post(urls.getListUrl, params, header);
  },
  //资源列表
  getResList(params, header) {
    return api.post(urls.getResListUrl, params, header);
  },
  //发送邮箱
  sendEmail(params, header) {
    return api.post(urls.mailUrl, params, header);
  },
};
