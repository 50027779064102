import axios from "axios";
import qs from "qs";
import browser from "./browser";

// 创建 axios 实例
let http = axios.create({
  //headers: {'Content-Type': 'application/json'},  //'application/x-www-form-urlencoded'
  timeout: 5000,
});

// 添加请求拦截器
http.interceptors.request.use(
  (config) => {
    if (config.method === "post" || config.method === "put") {
      // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
      if (!config.data.get) {
        config.data = qs.stringify(config.data);
      }
    } else if (config.method === "get" && browser.isIE) {
      // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
      let symbol = config.url.indexOf("?") >= 0 ? "&" : "?";
      config.url += symbol + "_=" + Date.now();
    }
    // 请求发送前进行处理
    return config;
  },
  (error) => {
    // 请求错误处理
    //return Promise.reject(error);
    window.location = "https://res.quyixian.com/error/?type=1&info=" + error;
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  (response) => {
    let { data } = response;
    return data;
  },
  (error) => {
    //响应错误
    //console.log(error);
    // window.location = "https://res.quyixian.com/error/?type=2&info=" + error;
    // let info = {};
    // let { status, statusText, data } = error.response;
    // if (!error.response) {
    //   info = {
    //     code: 5000,
    //     msg: "Network Error",
    //   };
    // } else {
    //   // 此处整理错误信息格式
    //   info = {
    //     code: status,
    //     data: data,
    //     msg: statusText,
    //   };
    // }
    // 请求错误处理
    //return Promise.reject(error);
  }
);

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
  return http;
}
