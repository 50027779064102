export default [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
  },
  {
    path: "/audiodetail",
    name: "audiodetail",
    component: () => import("@/views/audiodetail"),
  },
  {
    path: "/read",
    name: "read",
    component: () => import("@/views/read"),
  },
  {
    path: "/fullread",
    name: "fullread",
    component: () => import("@/views/fullRead"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index"),
  },
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/list"),
  },
];
