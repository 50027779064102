var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"res"},[_c('div',{staticClass:"res-box"},[_c('div',{staticClass:"res-unit"},[_vm._v(_vm._s(_vm.bookModel.bookName))]),_c('div',{staticClass:"res-cover"},[_c('van-image',{attrs:{"fit":"contain","src":_vm.bookModel.coverPicUrl
            ? _vm.bookModel.coverPicUrl
            : 'https://53english.cdn.53online.cn/cover.png'}})],1),_c('div',{staticClass:"res-title"},[_c('p',{staticClass:"res-title-left"},[_vm._v(_vm._s(_vm.resModel.catalogTitle))]),_c('p',{staticClass:"res-title-center"},[_vm._v(_vm._s(_vm.resModel.name))])]),_c('div',{staticClass:"res-progress"},[_c('div',{staticClass:"res-progress-timeText"},[_c('span',[_vm._v(_vm._s(_vm.progressText))]),_c('span',[_vm._v(_vm._s(_vm.totalTime))])]),_c('van-slider',{attrs:{"bar-height":"2px","inactive-color":"#FFD37D","active-color":"#FF8002"},on:{"change":_vm.progressChange},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"res-progress-button"})]},proxy:true}]),model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1),_c('div',{staticClass:"res-fun"},[_c('div',{staticClass:"loop",on:{"click":_vm.loopClick}},[_c('van-image',{attrs:{"fit":"contain","src":_vm.loop
              ? 'https://i.cdn.quyixian.com/53english/loop.png'
              : 'https://i.cdn.quyixian.com/53english/loop1.png'}})],1),_c('div',{staticClass:"upper"},[(_vm.upperShow)?_c('van-image',{attrs:{"fit":"contain","src":"https://i.cdn.quyixian.com/53english/upper.png"},on:{"click":_vm.upperClick}}):_c('van-image',{attrs:{"fit":"contain","src":"https://i.cdn.quyixian.com/53english/upper-grey.png"}})],1),_c('div',{staticClass:"play",on:{"click":_vm.togglePlaying}},[_c('van-image',{attrs:{"fit":"contain","src":_vm.audioState
              ? 'https://i.cdn.quyixian.com/53english/pause.png'
              : 'https://i.cdn.quyixian.com/53english/play.png'}})],1),_c('div',{staticClass:"next"},[(_vm.nextShow)?_c('van-image',{attrs:{"fit":"contain","src":"https://i.cdn.quyixian.com/53english/next.png"},on:{"click":_vm.nextClick}}):_c('van-image',{attrs:{"fit":"contain","src":"https://i.cdn.quyixian.com/53english/next-grey.png"}})],1),_c('div',{staticClass:"speed",on:{"click":function($event){_vm.speedShow = true}}},[_vm._v(_vm._s(_vm.speedText))])])]),_c('div',{staticClass:"res-footer"},[(_vm.resModel.hasNodes == 1)?_c('div',{staticClass:"res-footer-box res-footer-box1"},[_c('div',{staticClass:"res-footer-btn res-footer-btn2",on:{"click":function($event){_vm.listShow = true}}},[_vm._v(" 分题选听 ")])]):(_vm.resModel.hasFollowRead == 1)?_c('div',{staticClass:"res-footer-box res-footer-box2"},[(_vm.resModel.hasSectionLRC == 1)?[_c('router-link',{staticClass:"res-footer-btn res-footer-btn3",attrs:{"to":{
            name: 'fullread',
            query: {
              rid: _vm.resModel.id,
              rname: _vm.resModel.name,
              bid: _vm.bookModel.bookId,
              bname: _vm.bookModel.bookName,
              type: 4,
              title: '全文跟读',
            },
          }}},[_vm._v("全文跟读")]),(_vm.resModel.hasSentenceLRC == 1)?_c('router-link',{staticClass:"res-footer-btn res-footer-btn3",attrs:{"to":{
            name: 'read',
            query: {
              rid: _vm.resModel.id,
              rname: _vm.resModel.name,
              bid: _vm.bookModel.bookId,
              bname: _vm.bookModel.bookName,
              type: 3,
              title: '逐句跟读',
            },
          }}},[_vm._v("逐句跟读")]):_vm._e()]:[(_vm.resModel.hasWordLRC == 1)?_c('router-link',{staticClass:"res-footer-btn res-footer-btn3",attrs:{"to":{
            name: 'read',
            query: {
              rid: _vm.resModel.id,
              rname: _vm.resModel.name,
              bid: _vm.bookModel.bookId,
              bname: _vm.bookModel.bookName,
              type: 1,
              title: '词汇跟读',
            },
          }}},[_vm._v("词汇跟读")]):_vm._e(),(_vm.resModel.hasPhraseLRC == 1)?_c('router-link',{staticClass:"res-footer-btn res-footer-btn3",attrs:{"to":{
            name: 'read',
            query: {
              rid: _vm.resModel.id,
              rname: _vm.resModel.name,
              bid: _vm.bookModel.bookId,
              bname: _vm.bookModel.bookName,
              type: 2,
              title: '短语跟读',
            },
          }}},[_vm._v("短语跟读")]):_vm._e(),(_vm.resModel.hasSentenceLRC == 1)?_c('router-link',{staticClass:"res-footer-btn res-footer-btn3",attrs:{"to":{
            name: 'read',
            query: {
              rid: _vm.resModel.id,
              rname: _vm.resModel.name,
              bid: _vm.bookModel.bookId,
              bname: _vm.bookModel.bookName,
              type: 3,
              title: '句型跟读',
            },
          }}},[_vm._v("句型跟读")]):_vm._e()]],2):_vm._e()]),_c('audio',{ref:"inaudio",staticStyle:{"height":"0"},attrs:{"id":"inaudio","src":_vm.resModel.resFileUrl},on:{"play":_vm.onPlay,"pause":_vm.onPause,"ended":_vm.overAudio}}),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.speedShow),callback:function ($$v) {_vm.speedShow=$$v},expression:"speedShow"}},[_c('div',{staticClass:"speed-popup"},[_c('div',{staticClass:"speed-popup-title"},[_vm._v("请选择倍速")]),_c('ul',{staticClass:"speed-popup-list"},_vm._l((_vm.speedList),function(item){return _c('li',{key:item.key,class:item.key == _vm.speed ? 'cur' : '',on:{"click":function($event){return _vm.speedClick(item.key, item.value)}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0),_c('div',{staticClass:"speed-popup-close",on:{"click":function($event){_vm.speedShow = false}}},[_vm._v("关闭")])])]),_c('van-popup',{attrs:{"round":"","closeable":"","position":"bottom"},model:{value:(_vm.listShow),callback:function ($$v) {_vm.listShow=$$v},expression:"listShow"}},[_c('div',{staticClass:"block-popup"},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item,idx){return _c('div',{key:idx,staticClass:"block-list"},[_c('div',{staticClass:"block-list-title"},[_vm._v(_vm._s(item.section))]),_c('div',{staticClass:"block-list-content"},_vm._l((item.values),function(j,jdx){return _c('span',{key:jdx,on:{"click":function($event){return _vm.clickLevels(idx, jdx, j.start)}}},[_vm._v(_vm._s(j.title))])}),0)])}):_c('div',{staticClass:"block-popup-nolist"},[_vm._v("无小节数据")])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }