var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recorder"},[(_vm.fullFile != '' && _vm.resList.length > 0)?[_c('div',{staticClass:"recorder-head"},[_c('div',{staticClass:"recorder-head-left",on:{"click":_vm.back}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1),_c('div',{staticClass:"recorder-head-title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"recorder-list1"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.resList[0].evaWord == '' ? _vm.resList[0].word : _vm.resList[0].evaWord
        )}})]),_c('div',{staticClass:"recorder-fun recorder-fun1"},[(_vm.resList[0].isReading)?_c('div',{staticClass:"recorder-fun-box recorder-fun-audio",on:{"click":_vm.clickReadPause}},[_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/horn.png"}}),_c('p',[_vm._v("播放中")])],1):[(_vm.resList[0].isRecodering || _vm.resList[0].isAudioing)?_c('div',{staticClass:"recorder-fun-box recorder-fun-audio"},[_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/horn-grey.png"}}),_c('p',[_vm._v("播放原音")])],1):_c('div',{staticClass:"recorder-fun-box recorder-fun-audio",on:{"click":_vm.clickReadPlay}},[_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/horn.png"}}),_c('p',[_vm._v("播放原音")])],1)],(_vm.resList[0].isRecodering)?_c('div',{staticClass:"recorder-fun-box recorder-fun-recording",on:{"click":_vm.stopRecordAudio}},[_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/recording.png"}}),_c('p',[_vm._v("点击停止")])],1):_c('div',{staticClass:"recorder-fun-box recorder-fun-recording",on:{"click":function($event){return _vm.startRecordAudio(_vm.resList[0].isReading, _vm.resList[0].isAudioing)}}},[_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/recording.png"}}),_c('p',[_vm._v("点击录音")])],1),(_vm.resList[0].userScore && _vm.resList[0].userScore > 0)?[(
            _vm.resList[0].audioRecordUrl == '' &&
            (_vm.resList[0].isRecodering || _vm.resList[0].isReading)
          )?_c('div',{staticClass:"recorder-fun-box recorder-fun-recorded"},[_c('div',{staticClass:"recorder-fun-recorded-score"},[_vm._v(" "+_vm._s(_vm.resList[0].userScore)+" ")]),_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/score.png"}}),_c('p',[_vm._v("播放录音")])],1):(_vm.resList[0].isAudioing)?_c('div',{staticClass:"recorder-fun-box recorder-fun-recorded",on:{"click":_vm.clickItemPauseRecorder}},[_c('div',{staticClass:"recorder-fun-recorded-score"},[_vm._v(" "+_vm._s(_vm.resList[0].userScore)+" ")]),_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/score.png"}}),_c('p',[_vm._v("播放中")])],1):_c('div',{staticClass:"recorder-fun-box recorder-fun-recorded",on:{"click":_vm.clickItemPlayRecorder}},[_c('div',{staticClass:"recorder-fun-recorded-score"},[_vm._v(" "+_vm._s(_vm.resList[0].userScore)+" ")]),_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/score.png"}}),_c('p',[_vm._v("播放录音")])],1)]:_c('div',{staticClass:"recorder-fun-box recorder-fun-recorded"},[_c('div',{staticClass:"recorder-fun-recorded-score recorder-fun-recorded-score1"},[_vm._v(" 0 ")]),_c('van-image',{attrs:{"height":"30","fit":"contain","src":"https://i.cdn.quyixian.com/53english/score-grey.png"}}),_c('p')],1)],2),_c('div',{ref:'mxy0',staticClass:"read-box",attrs:{"id":'mxy0'}})]:_c('div',{staticClass:"recorder-no"},[_c('van-loading',{attrs:{"type":"spinner","vertical":""}},[_vm._v("加载中...")])],1),_c('audio',{ref:"audio",staticStyle:{"height":"0"},attrs:{"id":"audio","src":_vm.fullFile}}),_c('audio',{ref:"partAudio",staticStyle:{"height":"0"},attrs:{"id":"partAudio","src":_vm.audioCurrFile}}),_vm._m(0)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recorder-fixed",attrs:{"id":"fixed"}},[_c('canvas',{attrs:{"id":"canvas"}})])
}]

export { render, staticRenderFns }