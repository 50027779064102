import axios from './axios'

let instance = axios()
export default {
    get (url, params, headers) {
        let options = {}
        if (params) {
            options.params = params
        }
        if (headers) {
            options.headers = headers
        }
        return instance.get(url, options)
    },
    post (url, params, headers) {
        let options = {}
        if (headers) {
            options.headers = headers
        }
        return instance.post(url, params, options)
    },
    post1 (url, params, headers) {
        let options = {}
        if (headers) {
            options.headers = headers
        }
        params.push({'type':'upload'});
        return instance.post(url, params, options)
    },

    postImg(url,params,headers){
        return instance.post(url,params,headers);
    },
    put  (url, params, headers) {
        let options = {}
        if (headers) {
            options.headers = headers
        }
        return instance.put(url, params, options)
    },
    delete (url, params, headers) {
        let options = {}
        if (params) {
            options.params = params
        }
        if (headers) {
            options.headers = headers
        }
        return instance.delete(url, options)
    }
}
