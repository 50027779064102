import Vue from "vue";
import api from "./api/install";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import wechat from "@/assets/js/wechat";
import params from "@/assets/js/params";
import cookie from "@/assets/js/cookie";
import exif from "@/assets/js/exif";
import compress from "@/assets/js/compressor";
import { install } from "@icon-park/vue/es/all";
import animate from "animate.css";
import VConsole from "vconsole";

Vue.config.productionTip = false;

Object.assign(Vue.prototype, {
  $wechat: wechat,
  $params: params,
  $cookie: cookie,
  $exif: exif,
  $compress: compress,
});

install(Vue, "i");
//const vConsole = new VConsole(); .use(vConsole)
Vue.use(api).use(vant).use(animate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
