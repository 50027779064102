//2023print-manage
import EXIF from "exif-js";

export default {
  getOrientation: (file) => {
    return new Promise((resolve) => {
      EXIF.getData(file, function () {
        const orient = EXIF.getTag(this, "Orientation");
        resolve(orient);
      });
    });
  },

  dataURLtoFile: (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },

  rotateImage: (image, width, height) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.save();
    console.warn(width, height);
    canvas.width = width;
    canvas.height = height;
    ctx.rotate((0 * Math.PI) / 180);
    ctx.drawImage(image, 0, 0, width, height);
    ctx.restore();
    return canvas.toDataURL("image/jpeg");
  },
  ifHighThanIos13: () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const ios = userAgent.match(/cpu iphone os (.*?) like mac os/);
    if (!ios) {
      return false;
    }
    const iosVersion = ios[1].replace(/_/g, "."); // 获取ios版本
    const iosV1 = iosVersion.split(".")[0]; // 大版本号
    const iosV2 = iosVersion.split(".")[1]; // 小版本号
    if (Number(iosV1) < 13 || (Number(iosV1) === 13 && Number(iosV2) < 4)) {
      return false;
    }
    if (Number(iosV1) > 13 || (Number(iosV1) === 13 && Number(iosV2) >= 4)) {
      return true;
    }
    return false;
  },
};
